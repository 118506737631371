"use client";
import React, { FC, useState, useEffect } from "react";
import { useSearchParams } from 'next/navigation';
import imageActivites from "@/images/hero-right.png";
import imageEvenements from "@/images/hero-right-event.png";
import imageFlights from "@/images/hero-right-flight.png";
import HeroSearchForm from "../(client-components)/(HeroSearchForm)/HeroSearchForm";
import Image from "next/image";
import ButtonPrimary from "@/shared/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const searchParams = useSearchParams();
  const [currentImage, setCurrentImage] = useState(imageActivites);

  useEffect(() => {
    const cat = searchParams.get('cat');
    if (cat === "Événements") {
      setCurrentImage(imageEvenements);
    } else if (cat === "Vols privés") {
      setCurrentImage(imageFlights);
    } else {
      setCurrentImage(imageActivites);
    }
  }, [searchParams]);

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h1 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            Activités Aériennes & Événementielles
          </h1>
          <p className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Avec Esifly, réservez des activités aériennes inoubliables, des vols privés et des événements spéciaux en avion, hélicoptère et bien plus encore...
          </p>
          {/* <ButtonPrimary href="/listing-product-map" sizeClass="px-5 py-4 sm:px-7">
            Découvrez nos activités
          </ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          <Image className="w-full" src={currentImage} alt="hero" priority />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-60 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;