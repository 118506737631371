"use client";

import React, { FC, useEffect, useState, ReactNode } from "react";
import Heading from "@/shared/Heading";
import Nav from "@/shared/Nav";
import NavItem from "@/shared/NavItem";
import ButtonSecondary from "@/shared/ButtonSecondary";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab?: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "Latest Articles 🎈",
  onClickTab = () => {},
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);
  const router = useRouter();

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab(item);
    setTabActiveState(item);
    console.log(item);
    switch (item) {
      case "Paris":
        router.push("/listing-product-map?lat=48.8575475&lng=2.3513765&location=Paris,%20France")
        return
      case "Marseille":
        router.push("/listing-product-map?lat=43.3025742&lng=5.369074299999999&location=Marseille,%20France")
        return
      case "Nice":
        router.push("/listing-product-map?lat=43.7101728&lng=7.261953200000001&location=Nice,%20France")
        return
      case "Lyon":
        router.push("/listing-product-map?lat=45.764043&lng=4.835659&location=Lyon,%20France")
        return
      case "Lille":
        router.push("/listing-product-map?lat=50.624378&lng=3.0678588&location=Lille,%20France")
        return
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading} className="hidden lg:block">{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary href="/listing-product-map" className="!leading-none">
            <div className="flex items-center justify-center">
              <span>Voir tout</span>
              <ArrowRightIcon className="w-5 h-5 ml-3" />
            </div>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
